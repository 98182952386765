import { createWebHistory, createRouter } from 'vue-router';

import Home from '@/pages/Home'
import About from '@/pages/About'
import Contact from '@/pages/Contact'
import Legal from '@/pages/Legal'
import Blog1 from '@/pages/Blog1'
import Blog2 from '@/pages/Blog2'
import Blog3 from '@/pages/Blog3'

import NotFound from '@/pages/NotFound'

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    {
      path: '/terms',
      name: 'Legal',
      component: Legal
    },
    {
      path: '/top-xero-accountants-offer-free-xero-support-to-clients',
      name: 'Blog1',
      component: Blog1
    },
    {
      path: '/essential-guide-to-paye-software',
      name: 'Blog2',
      component: Blog2
    },
    {
      path: '/free-business-software-google-drive',
      name: 'Blog3',
      component: Blog3
    },
    {
      path: '/*',
      name: 'NotFound',
      component: NotFound
    }
  ]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
