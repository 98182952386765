<template>
  <div style="height:100vh;position:fixed;top:0;left:0;z-index:1000;background-color:#fff;width:100%;">


    <div style="padding:11px 19px;display:flex;justify-content:space-between;">
      <router-link :to="{ name: 'Home' }" class="c-nav__logo">
    	   <img src="https://cdn.side.nz/v/eea44b89-31fc-4b73-b44e-20a69b98b481.svg" style="height:40px">
      </router-link>
      <div @click.prevent="toggleMenu">
        <div class="c-nav--mobile__burger">
        <div id="nav-icon2" class="open">
          <span class=""></span>
          <span class=""></span>
          <span class=""></span>
          <span class=""></span>
          <span class=""></span>
          <span class=""></span>
        </div>
      </div>
      </div>
    </div>


      <div style="display:flex;flex-direction:column;padding-left:40px;padding-top:100px;">

        <div style="display:flex;align-items:center;height:55px;">
        <router-link :to="{ name: 'Home' }" @click.native="toggleMenu" style="font-size:22px;font-weight:600;">Home</router-link>
        <svg style="margin-left:10px;-webkit-transform: rotate(180deg);transform: rotate(180deg);" width="16px" height="8px" viewBox="0 0 16 8" version="1.1" xmlns="http://www.w3.org/2000/svg">

              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="baseline-arrow_right_alt-24px" transform="translate(-4.000000, -8.000000)">
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                      <polygon id="Shape" fill="#FF3465" fill-rule="nonzero" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) " points="16.01 11 4 11 4 13 16.01 13 16.01 16 20 12 16.01 8"></polygon>
                  </g>
              </g>
          </svg>
        </div>

        <div style="display:flex;align-items:center;height:55px;">
        <router-link :to="{ name: 'About' }" @click.native="toggleMenu" style="font-size:22px;font-weight:600;">About</router-link>
        <svg style="margin-left:10px;-webkit-transform: rotate(180deg);transform: rotate(180deg);" width="16px" height="8px" viewBox="0 0 16 8" version="1.1" xmlns="http://www.w3.org/2000/svg">

              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="baseline-arrow_right_alt-24px" transform="translate(-4.000000, -8.000000)">
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                      <polygon id="Shape" fill="#FF3465" fill-rule="nonzero" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) " points="16.01 11 4 11 4 13 16.01 13 16.01 16 20 12 16.01 8"></polygon>
                  </g>
              </g>
          </svg>
        </div>

        <div style="display:flex;align-items:center;height:55px;">
        <router-link :to="{ name: 'Contact' }" @click.native="toggleMenu" style="font-size:22px;font-weight:600;">Contact</router-link>
        <svg style="margin-left:10px;-webkit-transform: rotate(180deg);transform: rotate(180deg);" width="16px" height="16px" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg">

              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="baseline-arrow_right_alt-24px" transform="translate(-4.000000, -8.000000)">
                      <polygon id="Shape" points="0 0 24 0 24 24 0 24"></polygon>
                      <polygon id="Shape" fill="#FF3465" fill-rule="nonzero" transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) " points="16.01 11 4 11 4 13 16.01 13 16.01 16 20 12 16.01 8"></polygon>
                  </g>
              </g>
          </svg>
        </div>


        <a href="tel:078389700" style="margin-top:19px;height:55px;font-size:22px;font-weight:600;">07 838 9700</a>
        <a href="https://login.xero.com/identity/user/login" style="height:55px;font-size:22px;font-weight:600;">Xero Login</a>
        <router-link :to="{ name: 'Contact' }" @click.native="toggleMenu" class="c-button" style="max-width:120px;color: white;margin: 32px 0 0 0;">Sign up</router-link>
      </div>



  </div>
</template>

<script>
export default {
  methods: {
    toggleMenu(){
      this.$emit('toggleMenu')
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
