<template>
  <main id="content" class="no-banner" role="main">

  	<section class="c-product-focus" style="padding-bottom:0;margin-top: 60px;">
    <div class="container">
      <div class="row">
        <div class="col-md c-align--center">
          <h2 class="col-md-10">Here for you New Zealand</h2>
          <p class="col-md-8">goBeanie was established on April 1 2012 in Hamilton. <br><br>We are an NZ  based software provider selling best of breed apps and software for small business. <br><br>We source and select accounting software based on our intimate and longstanding knowledge of NZ business and accounting, and deliver this to customers with free training and ongoing support. Yip.  Xero software and full training.</p>

  	   <p class="col-md-8">We have extensive knowledge of small business and accounting and understand the pressure to stay up to date with technological and  industry. goBeanie provides advice and software to businesses in the Waikato, but also sells beyond to other customers.</p>

  	   <div class="c-product-focus__media">

          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="c-hero--split background--putty h-overhang no-transition">
  	<div class="c-image paul-mobile-div">
      <img class="h-image-contain paul-mobile-img" src="https://cdn.side.nz/v/b4065da3-2a19-4c48-a817-b4f4d965127f.png" alt="" style="border-radius:50%;">
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-5 c-valign--middle">
          <h1 class="t-white h2">Meet Paul</h1>
          <p>Paul's been helping get people set up and running with Xero for 7 years now, and over that time have helped thousands of businesses achieve their financial goals with the software. He's triple certified by Xero.</p>
  		<p>Paul is our resident xero expert</p>
        </div>
      </div>
    </div>
  </section>


  	<section class="c-cols c-cols-alternate no-bottom-padding">
      <div class="container">
        <div class="row column-reverse">
          <div class="col-md-5 order-1">
            <div class="c-image c-image--left c-image--shadow colorblock--top--left colorblock--eggplant circle--top--right circle--mint xero-mobile">
              <img src="https://cdn.side.nz/v/22e4fb07-e406-4fdd-8fb6-425b89c07ec4.svg" alt="" style="height: 260px;">
            </div>
          </div>
          <div class="col-md-4 offset-md-2 c-valign--middle order-2">
            <div class="c-cols__copy">
              <h2>Xero Platinum Partners</h2>
              <p>We're a certified Xero Platinum Partner, and one of the first firms to fully convert to Xero. We're already old school experts and can help you with all your Xero needs.</p>
            </div>
          </div>
        </div>

      </div>
    </section>







  <section class="c-single-column">
      <div class="container">

        <div class="col-md-10 offset-md-1 c-single-column__container h-hairline">
          <h2>Get Xero Today</h2>
          <div class="c-single-column__buttons">
            <router-link :to="{ name: 'Contact' }" class="c-button">Sign Up Now</router-link>
          </div>
        </div>

        </div>
    </section>


  </main>
</template>

<script>
export default {
  created(){
    document.title = 'About - goBeanie'
  },
}
</script>

<style scoped>

  	.c-hero--split.h-overhang .c-image {
  		width: 300px;
  		left: 60vw;
  		top: 260px;
      height:300px;
  	}

  	.c-hero--split .c-image {
  		box-shadow: none;
  	}

  	.colorblock--eggplant::after {
  		background: transparent;
  	}

  	.c-cols .c-image--shadow img {
  		-webkit-box-shadow: none;
  		box-shadow: none;
  	}

  	.background--putty {
  		background-color: #fff;
  	}

  	.mobile-size {
  		height: 400px;
  	}

  	@media (max-width:670px) {
  		.mobile-size {
  			height: 160px;
  		}
  	}
  	@media (max-width:670px) {
  	.paul-mobile-div{
  		left: 65px!important;
  		top: -50px!important;
  		min-height: 300px!important;
  	}
  		.paul-mobile-img {
  			width: 300px!important;
  		}

  		.xero-mobile {
  			max-width: 80vw!important;
  			margin-top: 9px !important;
  			margin-bottom: -45px !important;
  		}
  	}
</style>
