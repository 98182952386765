<template>
  <main id="content" class="no-banner" role="main">
  <div class="c-legal" style="padding-left:15px; padding-right:15px;">

  <section class="c-full-text mobile-margin">
    <div class="c-full-text__container">



	<h2>Free Business Software – Google Drive</h2>
	<h4>11 May</h4>
 	<div class="featured-article-image">
    <img src="https://cdn.side.nz/v/ba690cf8-1f8d-4d52-8d4d-d204bb2b8eec.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image">
	</div>

<p>Here at goBeanie we love accounting software, but we also love the stuff that makes life easy for me and for you.</p>



<p>By now you might get the sense we’re big fans of google apps. That’s
because we believe in solid business strategies and because we use them
to our advantage! Google apps have actually streamlined our business
structures and internal and external communications in a way we’re
totally chuffed with.</p>



<p>To lay it straight on the line…if you’re not using <a href="https://www.google.com/drive/">Google drive</a> you  should be! I mean, why do you have to keep moving your data storage?</p>



<p>You used to store it on the hard disk in your computer until you were
 told that if your hard disk failed, you might have lost all your data.</p>



<p>So you went out and purchased a standalone mobile hard drive. Now
they tell you that even though you have your data stored on your
computer and your mobile hard drive, if they both fail, you certainly
have lost all of your data.</p>



<p>You can’t keep all of your data on USB drives. Even if you could, they might fail as well.</p>



<p>You have properly guessed by now that you need to move your data to a
 hard drive in the clouds. This is where Google are prepared to step in
and help you. It’s all about utilising the most efficient and simple
business apps so that you have more time to either do more work or have
time out. (And the choice is yours.)</p>



<h3>Safe data in Google’s hands</h3>



<p>You have to believe that your data is safe in Google’s hands. After
all, if Google lose data, the world will collapse having lost their
favourite search engine.</p>



<p>Seriously, your data is vitally important to you.</p>



<p>If you’re a novelist, you might lose the first 360 pages of your 400 page novel and need to start again.</p>



<p>Working as a dentist, you can’t afford to lose all of your client
data, especially after spending all those dollars upgrading from a paper
 to a digital system.</p>



<p>Available since April, 2012, this is the app that most people were
waiting for – it’s just that they didn’t know it. When the app went
live, it was like a darn good rumour that actually came true!</p>



<h3>What does the Google Drive business software actually do?</h3>



<p>It becomes your new best friend; by making your Google Docs account into an online and – equally importantly – offline storage system.</p>



<p>You gain from having a virtual folder on your computer where you can simply drag and drop files among:</p>



<ul>
  <li>Laptops</li>
  <li>Tablets</li>
  <li>Your Smartphone</li>
  <li>Desktop computers (real folder, not virtual)</li>
</ul>



<h3>How to install the app</h3>



<p>Go to the Google app store and follow the simple instructions. You
will find your Google Docs account changes to a Google Drive account.
Any future visits to docs.google will be redirected to Google Drive.</p>



<p>Don’t forget to download the app to all the locations where you would like to use the Google Drive.</p>



<p>Before you start worrying about your operating system, Google Drive
can probably work with your latest purchases. You can link between:</p>



<ul>
  <li>Windows 8 – it works in current beta mode</li>
  <li>Windows 7</li>
  <li>Windows Vista</li>
  <li>Windows XP – if you have an older Windows operating system, it’s beyond time to get up to date.</li>
  <li>Mac OS 10.6 10.7</li>
  <li>Android</li>
  <li>iOS 3.0 and those since then</li>
</ul>



<p>You can also access your Google Drive from the WWW through most browsers.</p>



<h3>Keeping files safe from data loss</h3>



<p>If you use the Google Drive on your desktop computer, you will need to drag and drop files to your Google Drive icon.</p>



<p>The drive will sync the new file immediately, but you must have online access for this action.</p>



<p>When you access your Google Drive online, you will be able to
retrieve and access those files from your tablet, Smartphone or laptop.
Make them part of your business plans now.</p>



<h3>The good news</h3>



<p>Your files are held in a reliable home – Google – and you always have
 the most up to date version available for you across whatever computer
form you intend to use.</p>



<p>There is no bad news, although if you intend to use large files you
may use up your free quota (5GB at Aug 2012) quite quickly and then you
will be into monthly fees to continue using your online drive.</p>



<p>You can still access your files if you go over quota, but you can’t update them.</p>



<p>Finally, the best news of all – if you convert your Word docs to Google Docs, they won’t count as part of your free storage. That’s great  business software.</p>



<p>Pretty cool if you ask me! These are the kinds of business applications we like and want to share with you.<br>Stay tuned for more from goBeanie. </p>



<p>Bye for now.</p>



<p>Get Google Drive <a href="https://www.google.com/drive/">Here</a></p>


	<div class="row profile-mobile-img">
  <div class="contact-profile">
    <img class="profile-img" src="https://cdn.side.nz/v/b4065da3-2a19-4c48-a817-b4f4d965127f.png">
    <div class="contact-profile-text">
      <span class="profile-title">Paul Mcglade</span><br>
      <span class="profile-number"><a href="tel:07-838-9701" style="text-decoration:none;">07 838 9700</a></span><br>
      <span class="profile-email"><a href="mailto:paul@slater.co.nz" style="text-decoration:none;">paul@slater.co.nz</a></span>
    </div>
  </div>
</div>


</div>
  </section>


  </div>
  </main>
</template>

<script>
export default {
  created(){
    document.title = 'Free Business Software Google Drive - goBeanie'
  },
}
</script>

<style scoped>
.profile-mobile-img {
  padding-top: 50px;
}
@media (max-width:670px) {
  .profile-mobile-img {
    padding-left: 33px;
  }
}

  @media (max-width:670px) {
    .c-legal {
      padding-top: 10px;
    }
  }

  .featured-article-image img {
    border-radius: 6px;
    height: 446px;
    width: 950px;
    object-fit: cover;
  }

  @media (max-width:670px) {
    .featured-article-image img {
      width: 100%;
      height: 100%;
    }
  }


  .c-full-text h2 {
    margin-top: 29px;
  }

.profile-title {
  padding-left: 23px;
font-size:20px;
}

.profile-number {
  padding-left: 23px;
font-size:20px;
  line-height:30px;
}

.profile-email {
  padding-left: 23px;
font-size:20px
}

.contact-profile {
  margin-top:25px;
}

.contact-profile-text {
  float:left;
  padding-top: 12px;
}

.contact-profile-text a {
  color:#001E28;
  text-decoration:none;
  padding-top: 12px;
}

.contact-profile-text a:hover {
  text-decoration:underline
}

.profile-img {
  border-radius:50%;
  float:left;
  height: 100px;
}

  .c-legal li {
    font-size:20px!important;
  }

  .c-full-text .c-full-text__container {
    max-width: 950px;
  }

  h3 {
    margin-top:70px;
  }

  .c-full-text a {
    text-decoration: underline;
  }

  .c-full-text a:hover {
    text-decoration: underline;
  }

  @media (max-width:670px){
    .mobile-margin {
      margin-top: 80px;
    }
  }

  ul {
    margin: 50px;
  }

  li {
    margin: 10px 0;
  }
</style>
