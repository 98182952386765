<template>
  <div>
  <nav class="c-nav--mobile">
    <router-link :to="{ name: 'Home' }" class="c-nav__logo">
  	   <img src="https://cdn.side.nz/v/eea44b89-31fc-4b73-b44e-20a69b98b481.svg" style="height:40px">
    </router-link>

    <div @click.prevent="toggleMenu" class="c-nav--mobile__burger">
      <div id="nav-icon2">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </nav>

  <Menu v-if="menuOpen" @toggleMenu="toggleMenu"/>

  <nav class="c-nav">
    <div class="c-nav__container">
      <div class="container">
        <div class="c-nav__logo" style="">

          <router-link :to="{ name: 'Home' }" style="display: flex;justify-content: center;align-items: center;">
           <img src="https://cdn.side.nz/v/861c4baa-0dad-49a8-b568-e87e3284b587.svg" style="height:60px;">
         </router-link>


        </div>
        <ul class="c-nav__links">


          <li>
           <router-link :to="{ name: 'About' }">About</router-link>
          </li>

          <li>
            <router-link :to="{ name: 'Contact' }">Contact</router-link>
          </li>

  		  <a href="tel:078389700">
            <li>
             	07 838 9700
            </li>
          </a>
        </ul>
        <ul class="c-nav__utility">
          <a class="c-nav__login" target="_blank" href="https://login.xero.com/identity/user/login">
            <li>
  			        Xero Login
            </li>
          </a>

          <router-link :to="{ name: 'Contact' }" class="c-button"><li>
            Sign up
          </li></router-link>
        </ul>

      </div>
    </div>

  </nav>
</div>
</template>

<script>
import Menu from './Menu.vue'
export default {
  components: {
    Menu
  },
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    toggleMenu(){
      this.menuOpen = !this.menuOpen
    }
  }

}
</script>

<style scoped>
.c-nav--mobile .c-nav__logo {
  top: 11px;
  left: 19px;
}
</style>
