<template>
  <main id="content" class="no-banner" role="main">
  <div class="c-legal" style="padding-left:15px; padding-right:15px;">

  <section class="c-full-text mobile-margin">
    <div class="c-full-text__container">



      <h2>Essential Guide to PAYE Software</h2>
      <h4>28 March</h4>
      <div class="featured-article-image">
          <img src="https://cdn.side.nz/v/a6b92431-b97b-4e85-babf-80b8fe39bc58.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image">
      </div>

      <p>Here’s a few tips on the best way to pay and organise your PAYE:</p>



      <h3>Don’t be late</h3>



      <p>A rule of thumb is to be organised, know your dates and know when you are paying and don’t pay past the due date</p>



      <h3>Hold off payment until <em>a day before</em> the due date</h3>



      <p>Why a day before?</p>



      <p>Many of you like to hold your payments to the last minute to <a href="https://ird.govt.nz/">IRD</a> (and we all know why!) But we actually propose you pay just <strong>one day</strong> earlier. This
          gives you a bit of room to move, so if anything goes wrong you have another day up your sleeve. <strong>Remember  you can forward-load payments on your internet banking so the payment  is processed on the day you select without you having to manually do it  on the day. </strong>These
          days we advise a forward load of the payment as it means you’ve dealt with the payment and can be assured there are no last minute glitches or problems with your computer or handheld etc.</p>



      <h3>CHOOSE THE BEST PAYROLL SOFTWARE</h3>



      <p>This is the most important aspect of PAYE.</p>



      <p>Business is often about lots of paperwork and time lines and lists and reminders. When you’re in business for the long haul it pays to make life as automated, low pressure, and stress free as possible.</p>



      <p>Payroll software is far preferable than doing it manually. All the good software now files all the forms for you and either pays the IRD on the 20th from your account. You can even have the total payroll including all the IRD stuff, Kiwisaver, Donations
          etc.. sorted each week and then delivered to IRD without you having a hand in it. That’s stress free!</p>



      <p>Our first rule on computer software is that it MUST be web based and online. If it’s not, you need to avoid it like the plague. Choose software that is in the cloud if you want to compete with the young and up and coming and last in business. If
          you have to duck back to work because you forgot to do the pays or you can’t do it from your deck-chair in Fiji (or our favorite Golbourne Deli in London,) then the software solution you’re using is outdated. That’s definitive.</p>



      <h3>Essential criteria for the best payroll software</h3>



      <p>Your new payroll software should have the following. This will make your PAYE experience streamlined and hassle free:</p>



      <ul>
          <li><strong>Relatively cheap and easy to use</strong></li>
          <li><strong>Web / online</strong></li>
          <li><strong>Accessible anywhere/anytime globally</strong></li>
          <li><strong>IRD Paperwork automatically filed</strong></li>
          <li><strong>No installation, backups or upgrades</strong></li>
          <li><strong>Updated with law changes</strong></li>
          <li><strong>Secure</strong></li>
          <li><strong>Support is included</strong></li>
      </ul>



      <p>Now if you’re still worried about PAYE and getting it done easily and quickly, then you probably have the WRONG software.</p>



      <p>There are plenty of options that have all the criteria listed above, which will simplify PAYE for you and your business.</p>



      <p>goBeanie and <a href="https://slater.co.nz/">Slater Chartered Accountants </a>likes SmartPayroll as it’s a fairly complete offering.</p>



      <p>If you’re a Xero buff then Xero now has its own and at least another 7 options listed on its add-ons page. There are a lot more specifically designed for the NZ environment. In fact the payroll software marketplace has grown so much in recent years
          there’s now something to suit everyone.</p>



      <p><strong><br>Most importantly, if you’re still scratching payroll out in a  paper book, then we suggest you may want to examine how technology can  positively impact your business and do something about it.</strong></p>


      <div class="row profile-mobile-img">
          <div class="contact-profile">
              <img class="profile-img" src="https://cdn.side.nz/v/b4065da3-2a19-4c48-a817-b4f4d965127f.png">
              <div class="contact-profile-text">
                  <span class="profile-title">Paul Mcglade</span><br>
                  <span class="profile-number"><a href="tel:07-838-9701" style="text-decoration:none;">07 838 9700</a></span><br>
                  <span class="profile-email"><a href="mailto:paul@slater.co.nz" style="text-decoration:none;">paul@slater.co.nz</a></span>
              </div>
          </div>
      </div>




  </div>
  </section>


  </div>
  </main>
</template>

<script>
export default {
  created(){
    document.title = 'Essential Guide to PAYE Software Stay Ahead - goBeanie'
  },
}
</script>

<style scoped>
	.profile-mobile-img {
		padding-top: 50px;
	}
	@media (max-width:670px) {
		.profile-mobile-img {
			padding-left: 33px;
		}
	}

		@media (max-width:670px) {
			.c-legal {
				padding-top: 10px;
			}
		}

		.featured-article-image img {
			border-radius: 6px;
			height: 446px;
			width: 950px;
			object-fit: cover;
		}

		@media (max-width:670px) {
			.featured-article-image img {
				width: 100%;
				height: 100%;
			}
		}


		.c-full-text h2 {
			margin-top: 29px;
		}

  .profile-title {
    padding-left: 23px;
	font-size:20px;
  }

  .profile-number {
    padding-left: 23px;
	font-size:20px;
	  line-height:30px;
  }

  .profile-email {
    padding-left: 23px;
	font-size:20px
  }

  .contact-profile {
    margin-top:25px;
  }

  .contact-profile-text {
    float:left;
    padding-top: 12px;
  }

  .contact-profile-text a {
    color:#001E28;
    text-decoration:none;
    padding-top: 12px;
  }

  .contact-profile-text a:hover {
    text-decoration:underline
  }

  .profile-img {
    border-radius:50%;
    float:left;
    height: 100px;
  }

		.c-legal li {
			font-size:20px!important;
		}

		.c-full-text .c-full-text__container {
			max-width: 950px;
		}

		h3 {
			margin-top:70px;
		}

		.c-full-text a {
			text-decoration: underline;
		}

		.c-full-text a:hover {
			text-decoration: underline;
		}

		@media (max-width:670px){
			.mobile-margin {
				margin-top: 80px;
			}
		}

    ul {
      margin: 50px;
    }

    li {
      margin: 10px 0;
    }
  </style>
