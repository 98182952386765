<template>
  <main id="content" class="no-banner" role="main">
  <div class="c-legal">

  <section class="c-full-text">
  <div class="c-full-text__container">

  <h2>Privacy policy</h2>
  <ol>
  	<li><strong>Introduction</strong>
  	<ol>
  		<li>goBeanie regards customer privacy as an important part of our relationship with our customers. The following privacy policy applies to all goBeanie users, and conforms to Internet privacy standards. If you have questions or concerns regarding this statement, you should first contact goBeanie at 07 838 9700</li>
  	</ol></li>

  	<li><strong>Collection of Information</strong>
  	<ol>
  		<li>In order to use the goBeanie website, we may require information from you in order to provide the best service possible. All correspondence may also be collected and stored, particularly in regard to sales, support and accounts, including Email. Any information collected by goBeanie is collected via correspondence from you or your company. This may be via the telephone, Email, mail, fax or directly through our website.</li>
  	</ol></li>

  	<li><strong>Use of Collection Information</strong>
  	<ol>
  	<li>Any details collected from goBeanie customers is required in order to provide you with our products and/or services, and a high level of customer service. Correspondence is recorded in order to provide service references, and to assist in our staff development.</li>
  	</ol></li>

  	<li><strong>Storage of Collected Information</strong>
  	<ol>
  	<li>The security of your personal information is important to us. When you enter sensitive information (such as credit card numbers) on our website, we encrypt that information using secure socket layer technology (SSL). When Credit Card details are collected, we simply pass them on in order to be processed as required. We never permanently store complete Credit Card details.</li>

  	<li>We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. If you have any questions about security on our Website, you can email us at info@gobeanie.co.nz.</li>
  	</ol></li>

  	<li><strong>Access to Collected Information</strong>
  	<ol>
  	<li>If your personally identifiable information changes, or if you no longer desire our service, you may correct, update, delete or deactivate it by emailing us at info@gobeanie.co.nz</li>
  	</ol></li>

  	<li><strong>Orders</strong>
  	<ol>
  	<li>If you purchase a product or service from us, we may request certain personally identifiable information from you. You may be required to provide contact information (such as name, Email, and postal address) and financial information (such as credit card number, expiration date).  We use this information for billing purposes and to fill your orders. If we have trouble processing an order, we will use this information to contact you.</li>
  	</ol></li>

  	<li><strong>Communications</strong>
  	<ol>
  	<li>goBeanie uses personally identifiable information for essential communications, such as</li>
  	<li>Emails, accounts information, and critical service details. We may also use this information for other purposes, including some promotional Emails. If at any time a customer wishes not to receive such correspondence, they can request to be removed from any mailing lists by emailing us at info@gobeanie.co.nz. </li>
  	<li>You will be notified when your personal information is collected by any third party that is not our agent/service provider, so you can make an informed choice as to whether or not to share your information with that party.</li>
  	</ol></li>

  	<li><strong>Third parties</strong>
  	<ol>
  	<li>goBeanie may at its discretion use other third parties to provide essential services on our site or for our business processes. We may share your details as necessary for the third party to provide that service.</li>
  	<li>These third parties are prohibited from using your personally identifiable information for any other purpose.</li>
  	<li>goBeanie does not share any information with third parties for any unknown or unrelated uses.</li>
  	</ol></li>

  	<li><strong>Legal</strong>
  	<ol>
  	<li>We reserve the right to disclose your personally identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, or legal process served on our Website.</li>
  	</ol></li>

  	<li><strong>Links</strong>
  	<ol>
  	<li>Links on the goBeanie site to external entities are not covered within this policy. The terms and conditions set out in this privacy statement only cover the domain name of goBeanie.</li>
  	</ol></li>

  	<li><strong>Changes to privacy policy</strong>
  	<ol>
  	<li>If we decide to change our privacy policy, we will post those changes to this privacy statement, the homepage, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this privacy statement at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, by Email, or by means of a notice on our homepage.</li>
  	</ol></li>

  	<li><strong>Training</strong>
  	<ol>
  	<li>Onsite training is available to all purchasers of goBeanie whose place of business is in The Waikato. Purchasers of goBeanie subscriptions who have businesses in NZ that are outside of The Waikato are also entitled to onsite training that is delivered via Skype or Google Hangouts. These are arranged directly via email with goBeanie Team.</li>
  	</ol></li>

  	<li><strong>Cancellation of your goBeanie Subscription</strong>
  	<ol>
  	<li>The customer has web access to their Xero file. Should you wish us to change the “Xero organisation” to your business this will incur a charge of $100 and Xero fees need to be paid till the end of the month connected. Once these are paid the Xero organisation will be changed to you. Your provided name and email address will be used for this transfer. Should we received no direction within 7 days or by the month end (whichever comes first) then we will archive the Xero file to stop additional subscription costs. Archiving involves another $100 charge directly from Xero themselves. So if you require the file please act promptly.</li>
  	</ol></li>

  	<li><strong>Currency</strong>
  	<ol>
  	<li>All prices on the goBeanie website are shown in NZ dollars only.</li>
  	</ol></li>

  	<li><strong>goBeanie Security Policy</strong>
  	<ol>
  		<li>goBeanie uses the eWAY Payment Gateway for its online credit card transactions.</li>
  		<li>eWAY  processes online credit card transactions for thousands of New Zealand  merchants, providing a safe and secure means of collecting payments via the Internet.</li>
  		<li>All online credit card transactions performed on this site using the eWAY gateway are secured payments.</li>
  		<li>payments are fully automated with an immediate response.</li>
  		<li>Your complete credit card number cannot be viewed by goBeanie or any outside party.</li>
  		<li>All transactions are performed under 128 Bit SSL Certificate.</li>
  		<li>All transaction data is encrypted for storage within eWAY’s bank-grade data centre, further protecting your credit card data.</li>
  		<li>eWAY is an authorised third party processor for all the major New Zealand banks.</li>
  		<li>eWAY at no time touches your funds; all monies are directly
  		transferred from your credit card to the merchant account held by
  		gobeanie.co.nz</li>
  		<li>For more information about eWAY and online credit card payments, please visit www.eway.com.au</li>
  	</ol></li>
  	</ol>

  	<h2>Delivery Policy</h2>
  	<ol>
  	<li><strong>Delivery of Services</strong>
  	<ol>
  	<li>After ordering online, you will receive an email confirmation from eWAY containing your order details (if you have provided your email address). We will normally confirm receipt of your order within a few minutes of ordering. We will attempt to send your software/license/access code via email within the next working day. If you wish to query a delivery please contact us at info@gobeanie.co.nz</li>
  	</ol></li>

  	</ol>
  </div>
  </section>


  </div>
  </main>
</template>

<script>
export default {
  created(){
    document.title = 'Privacy & Terms - goBeanie'
  },
}
</script>

<style scoped>

</style>
