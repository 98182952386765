<template>
  <div>
  <footer class="c-footer">
      <div class="container">

          <div class="footer__company">
              <div class="footer__logo">
  				<router-link :to="{ name: 'Home' }">
  					<img src="https://cdn.side.nz/v/2353670b-bcbf-4806-a111-293bfb081f4a.svg">
  				</router-link>
              </div>
              <div class="footer__social" style="width: 180px;"></div>
          </div>
          <div class="footer__links__container">
              <div class="footer__links">
                  <ul>
                      <span class="footer__title">Account</span>
                      <li><router-link :to="{ name: 'Contact' }">Sign up</router-link></li>
                      <li><a href="https://login.xero.com/identity/user/login" target="_blank">Xero Login</a></li>
                  </ul>
              </div>
              <div class="footer__links">
                  <ul>
                      <span class="footer__title">Support</span>
                      <li><a href="mailto:paul@slater.co.nz">Email Support</a></li>
                      <li><a href="tel:07-838-9700">Call Support</a></li>
                  </ul>
              </div>
              <div class="footer__links">
                  <ul>
                      <span class="footer__title">Company</span>
  					               <li><router-link :to="{ name: 'About' }">About</router-link></li>
                           <li><router-link :to="{ name: 'Contact' }">Contact</router-link></li>
                           <li><router-link :to="{ name: 'Legal' }">Privacy & Terms</router-link></li>
                  </ul>
              </div>
          </div>
      </div>
  </footer>

  <div class="c-footer__legal">
      <div class="container">
          <div class="c-footer__container">
              <span class="copyright">© goBeanie - Slater Charted Accountants</span>
              
          </div>
      </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
