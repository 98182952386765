<template>
  <main id="content" class="no-banner" role="main">

  <section class="c-gate-form c-gate-form--demo mobile-margin" id="choose-webinar-section1" style="padding-bottom:0;">
      <div class="container">
          <div class="row">
              <div class="col-md-8 c-gate-form__form">
                  <div class="">
                      <div class="col-lg-10 col-md-12">
                          <h2 class="t-resource-period--hot-pink">Contact Us</h2>
                          <p class="h-clear-bottom-margin">Fill out the form below to send us a message.</p>
                          <div class="c-form c-form__in-line no-top-padding ">

                            <div style="margin-top:50px;">
                            <input v-model="form.name" placeholder="John Smith" type="text" style="font-weight:500;margin: 5px 0;color:#3C3F40;font-size:15px;height:48px;padding:13px;width:100%;border:1px solid #D5D8DA;border-radius:4px;">
                            <input v-model="form.email" accept=""placeholder="name@example.com" type="text" style="font-weight:500;margin: 5px 0;color:#3C3F40;font-size:15px;height:48px;padding:13px;width:100%;border:1px solid #D5D8DA;border-radius:4px;">
                            <input v-model="form.phone" placeholder="09 123-4567" type="text" style="font-weight:500;margin: 5px 0;color:#3C3F40;font-size:15px;height:48px;padding:13px;width:100%;border:1px solid #D5D8DA;border-radius:4px;">
                            <textarea v-model="form.message" placeholder="How can we help you?" type="text" style="resize:vertical;min-height:100px;max-height:250px;font-weight:500;margin: 5px 0;color:#3C3F40;font-size:15px;height:48px;padding:13px;width:100%;border:1px solid #D5D8DA;border-radius:4px;"/>

                            <div class="h-gdpr" style="display: block; text-align:left;">
                            <span>Submitting this form you agree to our
                              <router-link :to="{ name: 'Legal' }">Privacy & Terms</router-link>
                            </span>
                            </div>

                            <div style="margin-top:25px;">
                              <vue-recaptcha
                              :sitekey="capkey"
                              :loadRecaptchaScript="true"
                              @verify="onVerify"
                              @expired="onExpired"
                              ref="recaptcha"
                              />
                            </div>

                            <div style="display:flex;margin-top:25px;">
                            <div @click.prevent="send" class="c-button">Send</div>
                            </div>


                            </div>

                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-md-4" style="padding-top: 33px;">
                  <div class="c-press-articles__copy">
                      <h3>Call Us</h3>
                      <p>Call us anytime by phone on <br><a href="tel:078389700">07 838 9700</a></p>
                  </div>
                  <div class="c-press-articles__copy">
                      <h3>Support</h3>
                      <p>If you need help with goBeanie contact our support team at <a href="mailto:support@gobeanie.co.nz">support@gobeanie.co.nz</a></p>
                  </div>

                  <div class="c-press-articles__copy">
                      <h3>General Questions</h3>
                      <p>Have a general question for us? Contact us at <a href="mailto:paul@slater.co.nz">paul@slater.co.nz</a></p>
                  </div>
              </div>
          </div>
      </div>
  </section>

  <section class="c-jumble c-jumble-background--putty c-jumble--align-left" style="margin-top:60px;">
      <div class="container">

        <div class="c-jumble__container col-md-12 no-padding">
          <div class="c-jumble__content extra-padding">

              <div class="container">
                <div class="row">
                    <div class="col-md-5 c-valign--middle">
                        <h2>Location</h2>
                        <p>Level 1, 127 Collingwood Street<br>Hamilton 3204</p>
				 	              <p>Free parking at the rear of the building</p>
                      </div>
                      <div class="col-md-7">
                          <div class="map-container">

                            <GoogleMap
                                :center="center"
                                :zoom="18"
                                style="width: 100%; height: 400px"
                                :api-key="mapKey"
                                map-type-id="roadmap"
                                >
                                <Marker
                                  :options="{ position: center }"
                                />
                            </GoogleMap>

                      	</div>
                      </div>
                    </div>
  	               </div>
  	              </div>
                </div>
              </div>
    </section>

  </main>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';
import axios from 'axios'
import { rejectError } from '@/helpers'
import { GoogleMap, Marker } from "vue3-google-map";
export default {
  components: {
	  vueRecaptcha,
    GoogleMap,
    Marker
  },
  data(){
    return {
      center: { lat: -37.7904910436618, lng: 175.2802241175592 },
      capkey: process.env.VUE_APP_CAPKEY,
      mapKey: process.env.VUE_APP_MAPKEY,
      sendClicked: false,
      form: {
        name: null,
        email: null,
        phone: null,
        message: null,
        verified: false,
        client: process.env.VUE_APP_CLIENT_EMAIL,
        contact: process.env.VUE_APP_CLIENT_NAME,
        domain: process.env.VUE_APP_DOMAIN
      }
    }
  },
  created(){
    document.title = 'Contact - goBeanie'
  },
  methods: {
    send(){
      if(this.sendClicked){
        console.log('user spamming')
      } else {
        if(this.form.verified) {
          this.sendClicked = true
          axios.post(`${process.env.VUE_APP_URL}/emails/form/submit`, this.form, { timeout: process.env.VUE_APP_TIMEOUT })
          .then(res => {
            this.form.verified = false
            this.resetRecaptcha()
            this.form.name = null
            this.form.email = null
            this.form.phone = null
            this.form.message = null
            this.sendClicked = false
            this.$toasted.error('Message sent', {position: "bottom-center", duration: 10000})
          })
          .catch(err => {
            this.sendClicked = false
            this.$toasted.error(rejectError(err), {position: "bottom-center", duration: 5000})
          })

          } else {
            this.$toasted.error('Beep boop?', {position: "bottom-center", duration: 5000})
          }
      }
    },
    onVerify(){
      this.form.verified = true
    },
    onExpired(){
      this.form.verified = false
    },
    resetRecaptcha () {
      this.$refs.vueRecaptcha.reset()
    }
  }
}
</script>

<style scoped>

@media (max-width:670px) {
  .mobile-margin {
    margin-top: 50px;
  }
}
</style>
