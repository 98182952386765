<template>
  <main id="content" class="no-banner" role="main">
  <div class="c-legal" style="padding-left:15px; padding-right:15px;">

  <section class="c-full-text mobile-margin">
  <div class="c-full-text__container">




  	<h2>Top Xero Accountants Offer Free Xero Support to Clients</h2>
  	<h4>Febuary 25, 2019</h4>
    <div class="featured-article-image">
      <img src="https://cdn.side.nz/v/a98b48b9-6b2a-40e5-a623-2cc26523479d.jpg" class="attachment-post-thumbnail size-post-thumbnail wp-post-image">
    </div>


      <p></p>

      <h3><strong>Your Xero with goBeanie and Slater</strong></h3>

      <p>Here at goBeanie we're in the business of making things easy for our  clients. We enjoy supplying Xero to our clients and we enjoy partnering  with <a href="https://slater.co.nz/">Slater Chartered Accountants in Hamilton</a> to provide a seamless and easy Free Xero Training and 24/7 Support to clients. It's the only way to roll.</p>

      <p>There's no doubt Xero has changed the landscape of small business in New Zealand. And there's no doubt those beanies who got in early are  perfectly placed to ensure their clients are now well ahead of the rest  when it comes to implementation, use and full development of Xero within  their Hamilton small business and beyond. </p>

      <p><strong>Brett Slater was the first Waikato Chartered Accountant to embrace Xero</strong> and promote it as a game-changer within the profession. That was over 5 years ago, and now, in late 2016, Slaters has a well worn and solid  track record of supplying and educating all its clients on the ins and  outs and how to's of Xero software. </p>

      <p></p>

      <h3><strong>Free Xero Training in Your Office or Small Business</strong></h3>

      <p><strong> Paul is the Xero specialist</strong>at <strong><a href="https://slater.co.nz/">Slater Chartered Accountants</a></strong> and is frequently out and about visiting many of the local businesses and professionals who use Xero. This personalised approach is yet another key service component that sets Slater part from the rest of  the accountants in Hamilton.</p>

      <p>Paul says, "we think it's good to visit the client at their place of  business, as it means they don't lose half a day of wages and work.  That's what happens when they have to attend a workshop or an off-premises training session. We'd all love to attend those  sessions,but the reality is many of us can't afford the time away from  our firm, surgery or small business."  </p>

      <p>For those who do prefer to have a lesson or workshop, that option is also available. <strong>Slater has a fully functioning Xero training area</strong>  set up permanently for all the clients int our Bryce Street premises.  "It's really good," he says. "I just duck out the front to the training  area near reception and meet the clients who want it in-house with us.  Sometimes they combine this with their free business development or  financials meetings with us. It all works well."</p>

      <p><a href="https://www.xero.com/blog/">Xero</a> is one of those programs everyone loves. It's made a big difference to  trusted financial advisors and to business owners of all shapes and  size. It fits, and now, with so many add-ons as clever adjuncts, it's  fairly seamless for the user. It makes small business accounting that  much easier for all. </p>

      <p>Think about it today. Xero with Slater and goBeanie means you get your Xero subscription with the additional on-call help and advice you really need. </p>

      <p>More than a one-off workshop or lesson.</p>

      <p><strong>We love to help with all your boring stuff.</strong></p>



      <p>Email Paul now at <a href="mailto:paul@slater.co.nz">paul@slater.co.nz</a> or call him on <a href="tel:078389700">07 838 9700</a> to arrange your Xero stuff.</p>


  	<div class="row profile-mobile-img">
    <div class="contact-profile">
      <img class="profile-img" src="https://cdn.side.nz/v/b4065da3-2a19-4c48-a817-b4f4d965127f.png">
      <div class="contact-profile-text">
        <span class="profile-title">Paul Mcglade</span><br>
        <span class="profile-number"><a href="tel:07-838-9701" style="text-decoration:none;">07 838 9700</a></span><br>
        <span class="profile-email"><a href="mailto:paul@slater.co.nz" style="text-decoration:none;">paul@slater.co.nz</a></span>
      </div>
    </div>
  </div>

  </div>
  </section>


  </div>
  </main>
</template>

<script>
export default {
  created(){
    document.title = 'Top Xero Accountants Offer Free Xero Support to Clients - goBeanie'
  },
}
</script>

<style scoped>
.profile-mobile-img {
  padding-top: 50px;
}
@media (max-width:670px) {
  .profile-mobile-img {
    padding-left: 33px;
  }
}

  @media (max-width:670px) {
    .c-legal {
      padding-top: 10px;
    }
  }

  .featured-article-image img {
    border-radius: 6px;
    height: 446px;
    width: 950px;
    object-fit: cover;
  }

  @media (max-width:670px) {
    .featured-article-image img {
      width: 100%;
      height: 100%;
    }
  }


  .c-full-text h2 {
    margin-top: 29px;
  }

.profile-title {
  padding-left: 23px;
  font-size:20px;
}

.profile-number {
  padding-left: 23px;
  font-size:20px;
  line-height:30px;
}

.profile-email {
  padding-left: 23px;
  font-size:20px
}

.contact-profile {
  margin-top:25px;
}

.contact-profile-text {
  float:left;
  padding-top: 12px;
}

.contact-profile-text a {
  color:#001E28;
  text-decoration:none;
  padding-top: 12px;
}

.contact-profile-text a:hover {
  text-decoration:underline
}

.profile-img {
  border-radius:50%;
  object-fit: cover;
  float:left;
  height: 100px;
}

  .c-legal li {
    font-size:20px!important;
  }

  .c-full-text .c-full-text__container {
    max-width: 950px;
  }

  h3 {
    margin-top:70px;
  }

  .c-full-text a {
    text-decoration: underline;
  }

  .c-full-text a:hover {
    text-decoration: underline;
  }

  @media (max-width:670px){
    .mobile-margin {
      margin-top: 80px;
    }
  }
</style>
