<template>
  <main id="content" class="no-banner" role="main">

  <section class="c-hero--split c-hero--split--animation background--hot-pink to-the-left mobile-off">
        <div class="container">
          <div class="row">
            <div class="col-md c-valign--middle">
              <h1>Accounting made simple</h1>
              <p>A one-ledger cloud based system guaranteed to change the way you do the books.</p>
              <div class="c-hero__buttons">
                <router-link :to="{ name: 'Contact' }" class="c-button c-button--white">Sign up</router-link>
              </div>
            </div>
            <div class="col-md c-valign--middle">
              <div class="c-image">
                <img src="https://cdn.side.nz/v/26ab928b-cf49-4f98-88b5-96c5433362cd.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </section>

  	<section class="c-carousel mobile-padding" style="">
        <div class="container">
          <div class="row">
            <div class="col-md">
  						<h2>Business accounting software for you.</h2>
  						<p>goBeanie sets you up and trains you with Xero. Take your business to the next level.</p>
              <div class="c-carousel-video rectangle--gray rectangle--top--left">

                  <video class="c-carousel-video-home d-md-flex" loop muted autoplay>
                    <!-- <source src="img/xero-video.webm" type="video/webm"> -->
                      <source src="https://cdn.side.nz/v/1a72054b-5938-4ed3-b977-188d896d556c.mp4" type="video/mp4">
                      Sorry, your browser doesn't support embedded videos.
                  </video>
  				      <!-- <img class="d-flex" src="https://cdn.side.nz/v/8af4e146-ce10-417b-a5d1-820dca8c521d.jpg" alt=""> -->
              </div>

            </div>
          </div>
        </div>
      </section>



  <section class="c-pricing-hero--cornflower" style="padding-top:90px;">
    <div class="container" id="pricinghero">
         <h2>Choose the right plan for your business.</h2>
         <p>Xero comes in a few options with Standard being the most popular choice for small business owners.</p>
    </div>

      <div class="container">
        <div class="c-pricing-hero__plans-container square--top--left square--hot-pink circle--bottom--right circle--volt">
                    <div class="c-pricing-hero__plans ">
              <div class="c-pricing-hero__plans__title ">
                              <h3>Starter</h3>
              </div>
              <div class="c-pricing-hero__plans__content">
                <div class="c-pricing-hero__billing">
                  <div class="c-pricing-hero__annual desc js-pricingannual" style="display: block;">$27.50</div>
                                    <span class="c-pricing-hero__sub">Per Month</span>
                                </div>
                <p class="p-sm">Best for startups or business that do little trading.</p>
                <div class="c-pricing-plans__footer">
                   <div class="c-pricing-plans__ctas">
  					<router-link :to="{ name: 'Contact' }" class="c-button c-button--white">Start Now</router-link>
  				 </div>

                </div>
              </div>
            </div>
                    <div class="c-pricing-hero__plans featured">
              <div class="c-pricing-hero__plans__title featured">
                <p class="p-sm">MOST POPULAR</p>              <h3>Standard</h3>
              </div>
              <div class="c-pricing-hero__plans__content">
                <div class="c-pricing-hero__billing">
                  <div class="c-pricing-hero__annual desc js-pricingannual" style="display: block;">$60</div>

                    <span class="c-pricing-hero__sub">Per Month</span>
                </div>
                <p class="p-sm">Best for most business trading daily.</p>
                <div class="c-pricing-plans__footer">
                   <div class="c-pricing-plans__ctas">
                     <router-link :to="{ name: 'Contact' }" class="c-button c-button--white">Start Now</router-link>

                  </div>


                </div>
              </div>
            </div>
            <div class="c-pricing-hero__plans ">
              <div class="c-pricing-hero__plans__title ">
                  <h3>Premium</h3>
              </div>
              <div class="c-pricing-hero__plans__content">
                <div class="c-pricing-hero__billing">
                  <div class="c-pricing-hero__annual desc js-pricingannual" style="display: block;">$75</div>
                     <span class="c-pricing-hero__sub">Per Month</span>
                </div>
                <p class="p-sm">Best for business that trade internationally.</p>
                <div class="c-pricing-plans__footer">
                    <div class="c-pricing-plans__ctas">
                      <router-link :to="{ name: 'Contact' }" class="c-button c-button--white">Start Now</router-link>
                    </div>
                </div>
              </div>
            </div>
         </div>
      </div>

  </section>

  <div class="l-pricing-tables--container mobile-off">
  <section class="c-pricing-table--cornflower  nav-added">
          <div class="c-pricing-table--cornflower__navigation js-pricing-nav">
        <div class="c-pricing-table__row--cornflower">
          <div class="container c-pricing-table--cornflower__navigation--container">
            <div class="c-pricing-table__info--cornflower"><p>Compare Features</p></div>
            <div class="c-pricing-table__tier--cornflower"><p>Starter</p></div>
            <div class="c-pricing-table__tier--cornflower js-truncate-string"><p>Standard</p></div>
            <div class="c-pricing-table__tier--cornflower"><p>Premium</p></div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="c-pricing-table__table--cornflower">
           <div class="c-pricing-table__row--cornflower">
            <div class="c-pricing-table__info--cornflower sm">Invoices and Quotes</div>
            <div class="c-pricing-table__tier--cornflower">
                <p>5 per month</p>
            </div>
            <div class="c-pricing-table__tier--cornflower">
              <img src="https://cdn.side.nz/v/4cf06ea8-eb86-4771-af62-c8bd6869d3cb.svg">
            </div>
            <div class="c-pricing-table__tier--cornflower">
                <img src="https://cdn.side.nz/v/4cf06ea8-eb86-4771-af62-c8bd6869d3cb.svg">
            </div>
          </div>
          <div class="c-pricing-table__row--cornflower">
            <div class="c-pricing-table__info--cornflower sm">Bills</div>
            <div class="c-pricing-table__tier--cornflower">
                   <p>5 per month</p>
            </div>
            <div class="c-pricing-table__tier--cornflower">
                   <img src="https://cdn.side.nz/v/4cf06ea8-eb86-4771-af62-c8bd6869d3cb.svg">
            </div>
            <div class="c-pricing-table__tier--cornflower">
                   <img src="https://cdn.side.nz/v/4cf06ea8-eb86-4771-af62-c8bd6869d3cb.svg">
            </div>
          </div>
          <div class="c-pricing-table__row--cornflower">
            <div class="c-pricing-table__info--cornflower sm">Reconcile bank transactions</div>
            <div class="c-pricing-table__tier--cornflower">
                 <p>20 per month</p>
            </div>
            <div class="c-pricing-table__tier--cornflower">
                 <img src="https://cdn.side.nz/v/4cf06ea8-eb86-4771-af62-c8bd6869d3cb.svg">
            </div>
            <div class="c-pricing-table__tier--cornflower">
                 <img src="https://cdn.side.nz/v/4cf06ea8-eb86-4771-af62-c8bd6869d3cb.svg">
            </div>
          </div>
  		<div class="c-pricing-table__row--cornflower">
            <div class="c-pricing-table__info--cornflower sm">Expenses</div>
            <div class="c-pricing-table__tier--cornflower">

            </div>
            <div class="c-pricing-table__tier--cornflower">
                <img src="https://cdn.side.nz/v/4cf06ea8-eb86-4771-af62-c8bd6869d3cb.svg">
            </div>
            <div class="c-pricing-table__tier--cornflower">
                <img src="https://cdn.side.nz/v/4cf06ea8-eb86-4771-af62-c8bd6869d3cb.svg">
            </div>
          </div>
          <div class="c-pricing-table__row--cornflower">
            <div class="c-pricing-table__info--cornflower sm">Handle multiple currencies</div>
            <div class="c-pricing-table__tier--cornflower">

            </div>
            <div class="c-pricing-table__tier--cornflower">
            </div>
            <div class="c-pricing-table__tier--cornflower">
                <img src="https://cdn.side.nz/v/4cf06ea8-eb86-4771-af62-c8bd6869d3cb.svg">
            </div>
          </div>

        </div>
      </div>
  </section>

  </div>

  <section class="c-list no-bottom-padding">
    <div class="container">
      <div class="c-list__header t-center" style="margin-bottom: 30px;">
        <h2 style="text-align: left;margin-left: 38px;font-size: 30px;">Optional Extras</h2>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="c-list__entry">
            <!--<img class="c-list__icon c-list__icon--top" src="/imgs/partners/partners-icon-technology.svg">-->
            <h4>Payroll</h4>
            <p class="p-sm">Add $10/mo to pay one person, then $1/mo for each additional person, to a max of 200</p>
            <a class="t-link" href="https://www.xero.com/nz/features-and-tools/accounting-software/payroll/">Learn More</a>
          </div>
        </div>
        <div class="col-md-4">
          <div class="c-list__entry">
           <!-- <img class="c-list__icon c-list__icon--top" src="/imgs/partners/partners-icon-services.svg">-->
            <h4>Projects</h4>
            <p class="p-sm">$10 for 1 user/mo + $7 for each additional active user / month.</p>
            <a class="t-link" href="https://www.xero.com/nz/features-and-tools/accounting-software/projects/">Learn More</a>
          </div>
        </div>

  		</div>
  	</div>
  </section>

  <div class="c-press__popular">

    <div class="container">
      <div class="row">
          <div class="col-md-4">
              <div class="c-card__entry notranslate">
                <!-- <a class="c-card__clickthrough" href="post1.html" target="_blank"></a> -->
                <router-link :to="{ name: 'Blog1' }" class="c-card__clickthrough"></router-link>
                  <div class="c-card__default__image" style="background-image: url('https://cdn.side.nz/v/a98b48b9-6b2a-40e5-a623-2cc26523479d.jpg');"></div>
                <div class="c-card__entry__content">
                  <div class="c-card__source">Blog</div>
                  <h4 class="c-card__entry-title">
                    <router-link :to="{ name: 'Blog1' }" class="underline_from_lefsettt">Top Xero Accountants Offer Free Xero Support to Clients</router-link>
                  </h4>
                </div>
                <div class="c-card__meta">
                  <p class="p-sm">Febuary 25, 2019</p>
                </div>
              </div>

            </div>

          <div class="col-md-4">
              <div class="c-card__entry notranslate">
                <router-link :to="{ name: 'Blog2' }" class="c-card__clickthrough"></router-link>
                   <div class="c-card__default__image" style="background-image: url('https://cdn.side.nz/v/a6b92431-b97b-4e85-babf-80b8fe39bc58.jpg');"></div>
                <div class="c-card__entry__content">
                  <div class="c-card__source">Blog</div>
                  <h4 class="c-card__entry-title">
                    <router-link :to="{ name: 'Blog2' }" class="underline_from_lefsettt">Essential Guide to PAYE Software Stay Ahead</router-link>
                  </h4>
                </div>
                <div class="c-card__meta">
                  <p class="p-sm">March 28, 2019</p>
                </div>
              </div>

            </div>

                  <div class="col-md-4">
              <div class="c-card__entry notranslate">
                <router-link :to="{ name: 'Blog3' }" class="c-card__clickthrough"></router-link>
                   <div class="c-card__default__image" style="background-image: url('https://cdn.side.nz/v/ba690cf8-1f8d-4d52-8d4d-d204bb2b8eec.jpg');"></div>
                <div class="c-card__entry__content">
                  <div class="c-card__source">Blog</div>
                  <h4 class="c-card__entry-title">
                    <router-link :to="{ name: 'Blog3' }" class="underline_from_lefsettt">Free Business Software – Google Drive</router-link>
                  </h4>
                </div>
                <div class="c-card__meta">
                  <p class="p-sm">May 11, 2019</p>
                </div>
              </div>

            </div>
           </div>
    </div>
  </div>



  <section class="c-single-column">
      <div class="container">
        <div class="col-md-10 offset-md-1 c-single-column__container h-hairline">
          <h2>Get Xero Today</h2>
          <div class="c-single-column__buttons">
            <router-link :to="{ name: 'Contact' }" class="c-button">Sign Up Now</router-link>
          </div>
        </div>
        </div>
    </section>


  </main>
</template>

<script>
export default {
  created(){
    document.title = 'goBeanie - Xero training - Hamilton NZ'
  },
}
</script>

<style scoped>

@media (max-width:670px) {
  .mobile-off {
    display:none;
  }
  .mobile-padding {
    margin-top:65px;
  }
}
</style>
