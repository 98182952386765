<template>
  <div id="app" style="width:100%;height:100vh;">
    <Header />
    <router-view :key="$route.path" />
    <Footer />
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  created(){
    console.log('Hello! I was delveloped by Side adam@side.nz');
  }
}
</script>

<style>
@import 'styles.css';

@font-face {
    font-family: 'Graphik-Light';
    src: url("./fonts/Graphik-Light-Web.eot");
    src: url("./fonts/Graphik-Light-Web.eot?#iefix") format("embedded-opentype"), url("./fonts/Graphik-Light-Web.woff2") format("woff2"), url("./fonts/Graphik-Light-Web.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
	font-display: fallback
}

@font-face {
    font-family: 'Graphik-Regular';
    src: url("./fonts/Graphik-Regular-Web.eot");
    src: url("./fonts/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"), url("./fonts/Graphik-Regular-Web.woff2") format("woff2"), url("./fonts/Graphik-Regular-Web.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
	font-display: fallback
}

@font-face {
    font-family: 'Graphik-Medium';
    src: url("./fonts/Graphik-Medium-Web.eot");
    src: url("./fonts/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"), url("./fonts/Graphik-Medium-Web.woff2") format("woff2"), url("./fonts/Graphik-Medium-Web.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
	font-display: fallback
}

.toasted .primary.error, .toasted.toasted-primary.error {
    background:#ff5555!important;
    /* font-family: ProximaNova, sans-serif; */
    font-size: 13px;
    font-weight: bold;
    /* text-shadow: 0 1px 1px rgba(0, 0, 0, 0.12); */
    border-radius: 8px;
    /* padding: 10px 16px; */
    color:#fff;
    height: 47.5px;
    -webkit-box-shadow: 0px 4px 14px rgba(0,0,0,0.15);
    box-shadow: 0px 4px 14px rgba(0,0,0,0.15);
    z-index: 9997;
}

@media only screen and (max-width: 600px) {
  .toasted .primary.error, .toasted.toasted-primary.error {
    border-radius: 0;
  }
}
</style>
